import React from 'react';
import Layout from '../../components/Layout/Layout'
import ReactPlayer from 'react-player'
const Videos = () => {
        const prop ={
            playing:true,
            controls:true,
        }
    return (
        <Layout>
        <div style={{marginTop:"60px" }}>
        <ReactPlayer 
        {...prop}
        url='https://www.youtube.com/watch?v=JkM29-2FGck&feature=emb_logo' 
        width='auto'
        />
        </div>
        </Layout>
    );
};

export default Videos;